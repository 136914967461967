import { useRef, useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form'
import '../../../PublicContent/Firms/VaultLink/Styles.scss'
import { TwoFactorAuthFormProps } from './Model'
import notify from 'devextreme/ui/notify'
import { useAuth, useConfig } from '../../../contexts'
import { GoToPage } from '../../../utils/goToPage'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import '../form.scss'
import { ChallengeEnum } from '../../../clients/api/auth'
import { HttpErrorCodes } from '../../../clients/api/auth/Model'
import { ErrorMessageProps } from '../../common/errorMessage/Model'

const errorMessageClear = new ErrorMessageProps()

const TwoFactorAuthVerifyCodeForm = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const location = useLocation()
    const { verify2FA, verifyAfterLogin2FA, username, loading, initializeRedirectToAppCount } = useAuth()
    const { commonConfig } = useConfig()

    const props = new TwoFactorAuthFormProps(username)
    const customContent = commonConfig.Content.TFAVerify
    const customContentCommon = commonConfig.Content.Common
    const formData = useRef({ ...props })

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            let response = null
            setErrorMessage(errorMessageClear)
            try {
                switch (location.state.isRegistered) {
                    case ChallengeEnum.MFA_SETUP_LM:
                        response = await verify2FA(formData.current.code)
                        break
                    case ChallengeEnum.SOFTWARE_TOKEN_MFA:
                        response = await verifyAfterLogin2FA(formData.current.code)
                        break
                    default:
                        break
                }
                if (response == null || response.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps(`Two Factor Authentication Error: ${response.message}`, ''))
                    return
                }
                GoToPage.App()
                initializeRedirectToAppCount()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Two Factor Authentication Error' : error.response.data.error.message
                notify(msg, 'error', 3000)
            }
        },
        [initializeRedirectToAppCount, location.state.isRegistered, verify2FA, verifyAfterLogin2FA]
    )

    return (
        <SingleCard {...customContent}>
            <form className={'two-factor-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item dataField={'code'} editorType={'dxTextBox'} editorOptions={authCodeEditorOptions}>
                        <RequiredRule message="Authentication code is required" />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : 'Authenticate'}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage.message !== '' ? (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    ) : null}
                    <Item>
                        <div className={'login-link'}>
                            {customContentCommon.backToLoginPrefix}
                            <Link to={'/'}>{customContentCommon.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </SingleCard>
    )
}

const authCodeEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Authentication Code',
    labelMode: 'floating',
    elementAttr: {
        'field-id': 'auth-code-id',
    },
}

const submitButtonAttributes = {
    class: 'submit-button',
    elementAttr: {
        'field-id': 'submit-button-code-id',
    },
}

export default TwoFactorAuthVerifyCodeForm
