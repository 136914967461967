import { useRef, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, PatternRule } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import ForgotPasswordFormProps from './Model'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import '../form.scss'
import './Styles.scss'
import { HttpErrorCodes } from '../../../clients/api/auth/Model'
import { Const } from '../../../const'
import { ErrorMessageProps } from '../../common/errorMessage/Model'

const errorMessageClear = new ErrorMessageProps()

const notificationText = "We've sent a code to reset your password. Check your inbox."

const ForgotPasswordForm = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const navigate = useNavigate()
    const { commonConfig } = useConfig()

    const { forgotPassword, username, loading } = useAuth()

    const customContent = commonConfig.Content.ForgotPassword
    const customContentCommon = commonConfig.Content.Common

    const props = new ForgotPasswordFormProps(username)
    const formData = useRef({ ...props })

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            try {
                setErrorMessage(errorMessageClear)
                const response = await forgotPassword(formData.current)
                if (response == null || response.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps(`Forgot Password Error: ${response?.message}`, ''))
                    return
                }
                navigate('/reset-password')
                notify(notificationText, 'info', 3000)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Forgot Password Form Error' : error.response.data.error.message
                notify(msg, 'error', 2000)
            }
        },
        [forgotPassword, navigate]
    )
    const emailEditorOptions = {
        stylingMode: 'outlined',
        label: 'Email',
        labelMode: 'floating',
        mode: 'email',
        elementAttr: {
            'field-id': 'email-code-id',
        },
    }

    const submitButtonAttributes = {
        class: 'submit-button',
        inputAttr: {
            'field-id': 'submit-button-code-id',
        },
    }
    return (
        <SingleCard {...customContent}>
            <form className={'forgot-password-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    <Item dataField={'email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
                        <RequiredRule message="Email is required" />
                        <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading === true ? <Loading /> : customContent.submitButtonText}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {errorMessage.message !== '' ? (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    ) : null}
                    <Item>
                        <div className={'login-link'}>
                            {customContentCommon.backToLoginPrefix}
                            <Link to={'/'}>{customContentCommon.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </SingleCard>
    )
}

export default ForgotPasswordForm
