import Form, { Item, Label, RequiredRule, PatternRule, CompareRule, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useCallback } from 'react'
import { IGeneralInformationCreateAccountProps, PersonTypeEnum, ProductTypeEnum, ProfileTypeEnum } from '../Model'
import { LabelTemplate } from '../../../common'
import { Link } from 'react-router-dom'
import { Const } from '../../../../const'
import { useConfig } from '../../../../contexts'

const GeneralInformationForm = (props: IGeneralInformationCreateAccountProps): JSX.Element => {
    const formData = props.data
    const formDisabled = !props.allowEdition
    const { commonConfig } = useConfig()
    const customContent = commonConfig.Content.Common
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            formData.people[0].email = formData.profile.accountName
            const previous = /\+1/g
            const matches = formData.people[0].phoneNumbers[0].phoneNumber.match(previous)
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (!matches) {
                formData.people[0].phoneNumbers[0].phoneNumber = '+1'.concat('', formData.people[0].phoneNumbers[0].phoneNumber)
            }
            if (props.people[0].type === undefined) {
                props.people[0].type = PersonTypeEnum.Admin
            }
            props.nextTab()
        },
        [props, formData.people, formData.profile.accountName]
    )

    const onProfileChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            if (e == null || e.value == null) return
            formData.profile.profileType = e.value
            props.onProfileTypeChanged(e.value)
        },
        [formData.profile, props]
    )

    const onProductChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            if (e == null || e.value == null) return
            formData.profile.productType = e.value
            props.onProductTypeChanged(e.value)
        },
        [formData.profile, props]
    )

    const profileEditorOptions = {
        stylingMode: 'outlined',
        labelTemplateText: 'Account Type',
        mode: 'text',
        dataSource: [ProfileTypeEnum.Individual, ProfileTypeEnum.Business],
        icon: false,
        id: 'profile',
        onValueChanged: onProfileChanged,
        elementAttr: {
            'field-id': 'profile-code-id',
        },
    }
    const productEditorOptions = {
        stylingMode: 'outlined',
        labelTemplateText: 'Product Type',
        mode: 'text',
        dataSource: [ProductTypeEnum.Standard, ProductTypeEnum.Pro],
        icon: true,
        id: 'product',
        tooltipText:
            'Standard accounts can only deposit and\r\nwithdraw fiat currency from/to a linked bank account.\r\nPro accounts can deposit & withdraw digital assets\r\nfrom/to mainnet & fiat currency from/to a linked bank account.',
        onValueChanged: onProductChanged,
        elementAttr: {
            'field-id': 'product-code-id',
        },
    }
    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'general-code-id' }}>
                <Item dataField={'people[0].firstName'} editorType={'dxTextBox'} editorOptions={nameEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="First name is required" />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'people[0].lastName'} editorType={'dxTextBox'} editorOptions={lastNameEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Last name is required" />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'profile.accountName'} editorType={'dxTextBox'} editorOptions={emailEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Email is required" />
                    <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'people[0].phoneNumbers[0].phoneNumber'} editorType={'dxTextBox'} editorOptions={phoneEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Phone number is required" />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'profile.password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions} disabled={formDisabled}>
                    <RequiredRule />
                    <PatternRule
                        pattern="^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                        message="At least 8 characters, 1 number, 1 uppercase character, 1 of the following !@#$%^&*"
                    />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'profile.confirmPassword'} editorType={'dxTextBox'} editorOptions={confirmedPasswordEditorOptions} disabled={formDisabled}>
                    <RequiredRule message="Password is required" />
                    <CompareRule message={'Passwords do not match'} comparisonType={'=='} comparisonTarget={() => formData.profile.password} />
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'profile.profileType'} editorType={'dxSelectBox'} editorOptions={profileEditorOptions} disabled={formDisabled}>
                    <Label render={LabelTemplate} />
                </Item>
                <Item dataField={'profile.productType'} editorType={'dxSelectBox'} editorOptions={productEditorOptions} disabled={formDisabled}>
                    <Label render={LabelTemplate} />
                </Item>
                {!formDisabled && (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                )}
                {!formDisabled && (
                    <Item>
                        <div className={'login-link'}>
                            {customContent.backToLoginPrefix}
                            <Link to={'/'}> {customContent.backToLoginLinkText}</Link>
                        </div>
                    </Item>
                )}
            </Form>
        </form>
    )
}

const nameEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'First Name',
    mode: 'text',
    icon: false,
    elementAttr: {
        'field-id': 'name-code-id',
    },
}

const lastNameEditorOptions = {
    stylingMode: 'outlined',
    mode: 'text',
    labelTemplateText: 'Last Name',
    icon: false,
    elementAttr: {
        'field-id': 'last-name-code-id',
    },
}
const emailEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Email',
    mode: 'email',
    icon: false,
    elementAttr: {
        'field-id': 'email-code-id',
    },
}
const phoneEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Phone Number',
    icon: false,
    mode: 'text',
    mask: '+1 (X00) 000-0000',
    maskRules: {
        X: /[02-9]/,
    },
    maskInvalidMessage: 'The phone must have a correct USA phone format',
    inputAttr: {
        autoComplete: 'new-phone',
    },
    elementAttr: {
        'field-id': 'phone-code-id',
    },
}
const passwordEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Password',
    mode: 'password',
    icon: false,
    elementAttr: {
        'field-id': 'password-code-id',
    },
}
const confirmedPasswordEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Confirm Password',
    mode: 'password',
    icon: false,
    elementAttr: {
        'field-id': 'confirm-password-code-id',
    },
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }

export default GeneralInformationForm
