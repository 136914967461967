import { SingleCardCustomizableData } from '../../common/single-card/Model'

export class ChangePasswordFormCustomizableData extends SingleCardCustomizableData {
    constructor(public userInputText: string = 'Email', public submitButtonText: string = 'Reset my password') {
        super()
    }
}
export default class ChangePasswordFormProps {
    constructor(public user: string = '', public password: string = '', public passwordNew: string = '', public passwordNewConfirm: string = '', public code?: string) {}
}
