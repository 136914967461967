import Form, { ButtonItem, ButtonOptions, CompareRule, GroupItem, Item, Label, RequiredRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { LabelTemplate } from '../../../common'
import { ICreateAccountPropsBase } from '../Model'
import { useConfig } from '../../../../contexts'

const PersonalDetailsForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition
    const currentDate = new Date()
    const calcYear = currentDate.getFullYear() - 18
    const calcMonth = currentDate.getMonth()
    const defaultMonth = calcMonth + 1
    const calcDay = currentDate.getDate()
    let defaultDate = ''
    const { imageBaseUrl } = useConfig()

    const lockImg = `${imageBaseUrl}/lock.png`
    if (formData.people[0].pii.birthdate === '') {
        defaultDate = `${calcYear}/${defaultMonth}/${calcDay}`
    } else {
        defaultDate = formData.people[0].pii.birthdate
    }
    const maxDate = new Date(calcYear, calcMonth, calcDay)
    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            if (formData.people[0].pii.birthdate === '') {
                formData.people[0].pii.birthdate = defaultDate
            }
            const selectedDOB = new Date(formData.people[0].pii.birthdate)
            const tempDate = selectedDOB.toISOString().split('T')[0]
            const formattedDOB = tempDate.replace(/-/g, '/')
            formData.people[0].pii.birthdate = formattedDOB
            e.preventDefault()
            props.nextTab()
        },
        [defaultDate, formData.people, props]
    )
    const birthDateEditorOptions = {
        labelTemplateText: 'Birthdate',
        acceptCustomValue: false,
        openOnFieldClick: true,
        stylingMode: 'outlined',
        max: maxDate,
        elementAttr: {
            'field-id': 'birthdate-code-id',
        },
    }
    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'personal-code-id' }}>
                <Item colSpan={2}>
                    <div className="policy-info">
                        We use this information to assign you time-zone specific help, link your bank account, & comply with proper travel rule requirements, which states that any
                        digital asset transaction that crosses a specific threshold must be accompanied by the personal information of the customer.
                    </div>
                </Item>
                <GroupItem>
                    <Item dataField={'people[0].pii.birthdate'} editorType={'dxDateBox'} editorOptions={birthDateEditorOptions} disabled={formDisabled}>
                        {/* <RangeRule message="You must be at least 18 years old"  /> */}
                        <RequiredRule message="Birthdate is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'accounts.bankAccounts[0].routing'} editorType={'dxTextBox'} editorOptions={bankRoutingEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Bank Routing Number is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'accounts.bankAccounts[0].accountNumber'} editorType={'dxTextBox'} editorOptions={bankAccountEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Bank Account number is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item
                        dataField={'accounts.bankAccounts[0].accountNumberConfirmed'}
                        editorType={'dxTextBox'}
                        editorOptions={confirmBankAccountEditorOptions}
                        disabled={formDisabled}
                    >
                        <RequiredRule message="Confirm Bank Account number" />
                        <CompareRule message={'Bank Account number do not match'} comparisonType={'=='} comparisonTarget={() => formData.accounts.bankAccounts[0].accountNumber} />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[0].pii.ssn'} editorOptions={ssnEditorOptions} editorType={'dxTextBox'} disabled={formDisabled}>
                        <RequiredRule message="Social security number is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                </GroupItem>
                <GroupItem caption={''}>
                    <Item dataField={'people[0].addresses[0].street'} editorType={'dxTextBox'} editorOptions={addressEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Street Address 1 is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[0].addresses[0].apartment'} editorType={'dxTextBox'} editorOptions={aptEditorOptions} disabled={formDisabled}>
                        <Label render={LabelTemplate} />
                    </Item>
                    <GroupItem colCount={2}>
                        <Item dataField={'people[0].addresses[0].city'} editorType={'dxTextBox'} editorOptions={cityEditorOptions} disabled={formDisabled}>
                            <RequiredRule />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'people[0].addresses[0].state'} editorType={'dxSelectBox'} editorOptions={stateEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'people[0].addresses[0].zip'} editorType={'dxTextBox'} editorOptions={zipEditorOptions} disabled={formDisabled}>
                            <RequiredRule />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'people[0].addresses[0].country'} editorType={'dxSelectBox'} editorOptions={countryEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                    </GroupItem>
                    <Item>
                        <div className="ssnInfo">
                            <p className="ssnText">
                                Why do we need your SSN?<br></br>
                                <br></br>
                                Federal regulations require us to verify your identity. Don&apos;t worry, your data is always encrypted and secure.
                            </p>
                            <img src={lockImg} alt="Lock" className="ssn-lock" />
                        </div>
                    </Item>
                </GroupItem>
                {/* Empty item placed here so the form can display properly in 2 columns */}
                {!formDisabled ? (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}
const bankRoutingEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Bank Routing Number',
    mode: 'number',
    elementAttr: {
        'field-id': 'bank-routing-code-id',
    },
}
const bankAccountEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Bank Account Number',
    mode: 'number',
    step: 0,
    elementAttr: {
        'field-id': 'bank-account-code-id',
    },
}
const confirmBankAccountEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Confirm Bank Account Number',
    mode: 'number',
    step: 0,
    elementAttr: {
        'field-id': 'confirm-bank-code-id',
    },
}

const ssnEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Social Security Number',
    mode: 'text',
    mask: '000000000',
    maskChar: ' ',
    elementAttr: {
        'field-id': 'ssn-code-id',
    },
}
const addressEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Street Address 1',
    mode: 'text',
    elementAttr: {
        'field-id': 'address-code-id',
    },
}
const aptEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Apt/Suite',
    mode: 'text',
    elementAttr: {
        'field-id': 'apt-code-id',
    },
}
const cityEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'City',
    mode: 'text',
    elementAttr: {
        'field-id': 'city-code-id',
    },
}
const zipEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Zip or Postal Code',
    mode: 'text',
    elementAttr: {
        'field-id': 'zip-code-id',
    },
}

const countryEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Country',
    mode: 'text',
    dataSource: [{ ID: 'US', Name: 'United States' }],
    displayExpr: 'Name',
    valueExpr: 'ID',
    elementAttr: {
        'field-id': 'country-code-id',
    },
}

const stateEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'State',
    mode: 'text',
    dataSource: [
        { ID: 'AL', Name: 'Alabama' },
        { ID: 'AK', Name: 'Alaska' },
        { ID: 'AS', Name: 'American Samoa' },
        { ID: 'AZ', Name: 'Arizona' },
        { ID: 'AR', Name: 'Arkansas' },
        { ID: 'CA', Name: 'California' },
        { ID: 'CO', Name: 'Colorado' },
        { ID: 'CT', Name: 'Connecticut' },
        { ID: 'DE', Name: 'Delaware' },
        { ID: 'DC', Name: 'District Of Columbia' },
        { ID: 'FM', Name: 'Federated States Of Micronesia' },
        { ID: 'FL', Name: 'Florida' },
        { ID: 'GA', Name: 'Georgia' },
        { ID: 'GU', Name: 'Guam' },
        { ID: 'HI', Name: 'Hawaii' },
        { ID: 'ID', Name: 'Idaho' },
        { ID: 'IL', Name: 'Illinois' },
        { ID: 'IN', Name: 'Indiana' },
        { ID: 'IA', Name: 'Iowa' },
        { ID: 'KS', Name: 'Kansas' },
        { ID: 'KY', Name: 'Kentucky' },
        { ID: 'LA', Name: 'Louisiana' },
        { ID: 'ME', Name: 'Maine' },
        { ID: 'MH', Name: 'Marshall Islands' },
        { ID: 'MD', Name: 'Maryland' },
        { ID: 'MA', Name: 'Massachusetts' },
        { ID: 'MI', Name: 'Michigan' },
        { ID: 'MN', Name: 'Minnesota' },
        { ID: 'MS', Name: 'Mississippi' },
        { ID: 'MO', Name: 'Missouri' },
        { ID: 'MT', Name: 'Montana' },
        { ID: 'NE', Name: 'Nebraska' },
        { ID: 'NV', Name: 'Nevada' },
        { ID: 'NH', Name: 'New Hampshire' },
        { ID: 'NJ', Name: 'New Jersey' },
        { ID: 'NM', Name: 'New Mexico' },
        { ID: 'NY', Name: 'New York' },
        { ID: 'NC', Name: 'North Carolina' },
        { ID: 'ND', Name: 'North Dakota' },
        { ID: 'MP', Name: 'Northern Mariana Islands' },
        { ID: 'OH', Name: 'Ohio' },
        { ID: 'OK', Name: 'Oklahoma' },
        { ID: 'OR', Name: 'Oregon' },
        { ID: 'PW', Name: 'Palau' },
        { ID: 'PA', Name: 'Pennsylvania' },
        { ID: 'PR', Name: 'Puerto Rico' },
        { ID: 'RI', Name: 'Rhode Island' },
        { ID: 'SC', Name: 'South Carolina' },
        { ID: 'SD', Name: 'South Dakota' },
        { ID: 'TN', Name: 'Tennessee' },
        { ID: 'TX', Name: 'Texas' },
        { ID: 'UT', Name: 'Utah' },
        { ID: 'VT', Name: 'Vermont' },
        { ID: 'VI', Name: 'Virgin Islands' },
        { ID: 'VA', Name: 'Virginia' },
        { ID: 'WA', Name: 'Washington' },
        { ID: 'WV', Name: 'West Virginia' },
        { ID: 'WI', Name: 'Wisconsin' },
        { ID: 'WY', Name: 'Wyoming' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    elementAttr: {
        'field-id': 'state-code-id',
    },
}

const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }
export default PersonalDetailsForm
