import { merge } from 'lodash'
import { useState, createContext, useContext, useEffect } from 'react'
import { ConfigContextModel, ConfigStateEnum } from './Model'
import { CommonConfigModel, EnvConfigModel } from '../../clients/api/config'
import { ConfigApi } from '../../clients/api/config/api'

const commonConfigContextEmpty = new CommonConfigModel()
const commonGeneralConfigContextEmpty = new CommonConfigModel()
const commonFirmConfigContextEmpty = new CommonConfigModel()
const envConfigContextEmpty = new EnvConfigModel()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ConfigProvider = (props: any): JSX.Element => {
    const [loadingCommonConfig, setLoadingCommonConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonConfig, setCommonConfig] = useState(commonConfigContextEmpty)
    const [loadingCommonGeneralConfig, setLoadingCommonGeneralConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonGeneralConfig, setCommonGeneralConfig] = useState(commonGeneralConfigContextEmpty)
    const [loadingCommonFirmConfig, setLoadingCommonFirmConfig] = useState(ConfigStateEnum.Unloaded)
    const [commonFirmConfig, setCommonFirmConfig] = useState(commonFirmConfigContextEmpty)
    const [loadingEnvConfig, setLoadingEnvConfig] = useState(ConfigStateEnum.Unloaded)
    const [envConfig, setEnvConfig] = useState(envConfigContextEmpty)
    const [configApi] = useState(new ConfigApi())

    const firm = process.env.REACT_APP_FIRM?.trim() ?? 'undefined'

    let envConfigured = process.env.REACT_APP_ENV?.trim() ?? 'undefined'
    switch (envConfigured) {
        case 'LOCAL':
        case 'DEV':
        case 'UAT':
        case 'PROD':
            break
        default:
            console.error(`Environment: ${envConfigured} unsupported`)
            envConfigured = 'LOCAL'
    }

    const environment = envConfigured

    const publicUrl = process.env.PUBLIC_URL ?? ''
    const imageBaseUrl = `${publicUrl}/images`
    const configBaseUrl = `${publicUrl}/firms`
    const configFirmBaseUrl = `${configBaseUrl}/${firm}`
    const firmImageBaseUrl = `${configFirmBaseUrl}/images`
    const commonConfigJson = 'COMMON.config.json'

    useEffect(() => {
        /** Get the environment configuration */
        if (loadingEnvConfig === ConfigStateEnum.Unloaded) {
            setLoadingEnvConfig(ConfigStateEnum.Loading)
            configApi.LoadConfig<EnvConfigModel>(`${configFirmBaseUrl}/env/${environment}.config.json`, setEnvConfig, setLoadingEnvConfig)
        }
        /** Get the COMMON Config general */
        if (loadingCommonGeneralConfig === ConfigStateEnum.Unloaded) {
            setLoadingCommonGeneralConfig(ConfigStateEnum.Loading)
            configApi.LoadConfig<CommonConfigModel>(`${configBaseUrl}/${commonConfigJson}`, setCommonGeneralConfig, setLoadingCommonGeneralConfig)
        }
        /** Get the COMMON Config by firm to override the general */
        if (loadingCommonFirmConfig === ConfigStateEnum.Unloaded) {
            setLoadingCommonFirmConfig(ConfigStateEnum.Loading)
            configApi.LoadConfig<CommonConfigModel>(`${configFirmBaseUrl}/${commonConfigJson}`, setCommonFirmConfig, setLoadingCommonFirmConfig)
        }
        setLoadingCommonConfig(
            loadingCommonGeneralConfig === ConfigStateEnum.Unloaded || loadingCommonFirmConfig === ConfigStateEnum.Unloaded
                ? ConfigStateEnum.Unloaded
                : loadingCommonGeneralConfig === ConfigStateEnum.Loading || loadingCommonFirmConfig === ConfigStateEnum.Loading
                ? ConfigStateEnum.Loading
                : ConfigStateEnum.Loaded
        )
        /** Set the common config */
        if (loadingCommonConfig === ConfigStateEnum.Loaded) {
            /** Override the common config with the firm config */
            const configMerged = merge(commonGeneralConfig, commonFirmConfig)
            setCommonConfig(configMerged)
        }
    }, [
        commonGeneralConfig,
        commonFirmConfig,
        configBaseUrl,
        configFirmBaseUrl,
        environment,
        firm,
        loadingCommonGeneralConfig,
        loadingCommonFirmConfig,
        loadingCommonConfig,
        loadingEnvConfig,
        envConfig,
        configApi,
    ])

    const configContextData = new ConfigContextModel(commonConfig, loadingCommonConfig, envConfig, loadingEnvConfig, firm, environment, imageBaseUrl, firmImageBaseUrl)

    return <ConfigContext.Provider value={{ ...configContextData }} {...props} />
}

const configContextEmpty = new ConfigContextModel()
const ConfigContext = createContext(configContextEmpty)

const useConfig = (): ConfigContextModel => useContext(ConfigContext)

export { ConfigProvider, useConfig }
