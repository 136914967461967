import { useConfig } from '../contexts'
import PublicContentFirmMapper from './FirmMapper'
import './Styles.scss'

const PublicContent = (): JSX.Element => {
    const { firm } = useConfig()

    const publicContent = 'PublicContent'
    const firmClassName = `${publicContent}${firm}`
    const IsFirmPublicContentMapped = firm in PublicContentFirmMapper
    const PublicContentFirm = IsFirmPublicContentMapped ? PublicContentFirmMapper[firm]() : <div id="no_firm_mapped" />

    return (
        <div className={firmClassName} id={publicContent}>
            {PublicContentFirm}
        </div>
    )
}

export default PublicContent
