import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule, GroupItem, PatternRule } from 'devextreme-react/form'
import { useCallback } from 'react'
import { ICreateAccountPropsBase, Person, Phone, PhoneTypeEnum, UBOTypeEnum } from '../Model'
import { LabelTemplate } from '../../../common'
import { Const } from '../../../../const'

// Needed flag to avoid calling unnecesary functions more than once
let renderFlag = false

const BusinessDetailsForm = (props: ICreateAccountPropsBase): JSX.Element => {
    const formData = props
    const allowEdition: boolean = props.allowEdition
    const formDisabled = !allowEdition

    if (!renderFlag) {
        formData.people.push(new Person())
        formData.people[1].phoneNumbers.push(new Phone(PhoneTypeEnum.Personal))
        renderFlag = true
    }

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            e.preventDefault()
            const previous = /\+1/g
            const matches = formData.people[1].phoneNumbers[0].phoneNumber.match(previous)
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (!matches) {
                formData.people[1].phoneNumbers[0].phoneNumber = '+1'.concat('', formData.people[1].phoneNumbers[0].phoneNumber)
            }
            if (props.people[1].type === undefined) {
                props.people[1].type = UBOTypeEnum.Contact
            }
            props.nextTab()
        },
        [props, formData.people]
    )
    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} colCount={2} elementAttr={{ 'field-id': 'business-code-id' }}>
                <Item colSpan={2}>
                    <div className="policy-info">
                        We collect this information to keep your account secure and comply with financial regulations, such as travel rule requirements which state that any digital
                        asset transaction that crosses a specific threshold must be accompanied by the personal information of the business.
                    </div>
                </Item>
                <GroupItem caption={' '} cssClass="outline">
                    <Item dataField={'business.name'} editorType={'dxTextBox'} editorOptions={legalNameEditorOptions}>
                        <RequiredRule message="Street Address 1 is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'business.addresses[0].street'} editorType={'dxTextBox'} editorOptions={addressEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Street Address 1 is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'business.addresses[0].apartment'} editorType={'dxTextBox'} editorOptions={aptEditorOptions} disabled={formDisabled}>
                        <Label render={LabelTemplate} />
                    </Item>
                    <GroupItem colCount={2}>
                        <Item dataField={'business.addresses[0].city'} editorType={'dxTextBox'} editorOptions={cityEditorOptions} disabled={formDisabled}>
                            <RequiredRule />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'business.addresses[0].state'} editorType={'dxSelectBox'} editorOptions={stateEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'business.addresses[0].zip'} editorType={'dxTextBox'} editorOptions={zipEditorOptions} disabled={formDisabled}>
                            <RequiredRule />
                            <Label render={LabelTemplate} />
                        </Item>
                        <Item dataField={'business.addresses[0].country'} editorType={'dxSelectBox'} editorOptions={countryEditorOptions} disabled={formDisabled}>
                            <RequiredRule message="Option is required" />
                            <Label render={LabelTemplate} />
                        </Item>
                    </GroupItem>
                </GroupItem>
                <GroupItem caption={'The principal point of contact for communications'} cssClass="outline">
                    <Item dataField={'people[1].firstName'} editorType={'dxTextBox'} editorOptions={nameEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="First name is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[1].lastName'} editorType={'dxTextBox'} editorOptions={lastNameEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Last name is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[1].title'} editorType={'dxTextBox'} editorOptions={titleEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Title is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[1].email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Email is required" />
                        <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                        <Label render={LabelTemplate} />
                    </Item>
                    <Item dataField={'people[1].phoneNumbers[0].phoneNumber'} editorType={'dxTextBox'} editorOptions={phoneEditorOptions} disabled={formDisabled}>
                        <RequiredRule message="Phone number is required" />
                        <Label render={LabelTemplate} />
                    </Item>
                </GroupItem>
                {!formDisabled ? (
                    <ButtonItem colSpan={2}>
                        <ButtonOptions elementAttr={submitButtonAttributes} width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">Continue</span>
                        </ButtonOptions>
                    </ButtonItem>
                ) : (
                    <></>
                )}
            </Form>
        </form>
    )
}

const addressEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Street Address 1',
    mode: 'text',
    icon: true,
    tooltipText:
        'This is where your company runs its operations.\r\nWe use this address to send documents and assign you time zone specific help.\r\nWe require a physical address, not a PO Box.',
    id: 'address',
    elementAttr: {
        'field-id': 'address-code-id',
    },
}
const aptEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Apt/Suite',
    mode: 'text',
    icon: false,
    id: 'apt',
    elementAttr: {
        'field-id': 'apt-code-id',
    },
}

const zipEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Zip or Postal Code',
    mode: 'text',
    icon: false,
    id: 'zip',
    elementAttr: {
        'field-id': 'zip-code-id',
    },
}

const cityEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'City',
    mode: 'text',
    icon: false,
    id: 'city',
    elementAttr: {
        'field-id': 'city-code-id',
    },
}

const countryEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Country',
    mode: 'text',
    dataSource: [{ ID: 'US', Name: 'United States' }],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'country',
    elementAttr: {
        'field-id': 'country-code-id',
    },
}

const stateEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'State',
    mode: 'text',
    dataSource: [
        { ID: 'AL', Name: 'Alabama' },
        { ID: 'AK', Name: 'Alaska' },
        { ID: 'AS', Name: 'American Samoa' },
        { ID: 'AZ', Name: 'Arizona' },
        { ID: 'AR', Name: 'Arkansas' },
        { ID: 'CA', Name: 'California' },
        { ID: 'CO', Name: 'Colorado' },
        { ID: 'CT', Name: 'Connecticut' },
        { ID: 'DE', Name: 'Delaware' },
        { ID: 'DC', Name: 'District Of Columbia' },
        { ID: 'FM', Name: 'Federated States Of Micronesia' },
        { ID: 'FL', Name: 'Florida' },
        { ID: 'GA', Name: 'Georgia' },
        { ID: 'GU', Name: 'Guam' },
        { ID: 'HI', Name: 'Hawaii' },
        { ID: 'ID', Name: 'Idaho' },
        { ID: 'IL', Name: 'Illinois' },
        { ID: 'IN', Name: 'Indiana' },
        { ID: 'IA', Name: 'Iowa' },
        { ID: 'KS', Name: 'Kansas' },
        { ID: 'KY', Name: 'Kentucky' },
        { ID: 'LA', Name: 'Louisiana' },
        { ID: 'ME', Name: 'Maine' },
        { ID: 'MH', Name: 'Marshall Islands' },
        { ID: 'MD', Name: 'Maryland' },
        { ID: 'MA', Name: 'Massachusetts' },
        { ID: 'MI', Name: 'Michigan' },
        { ID: 'MN', Name: 'Minnesota' },
        { ID: 'MS', Name: 'Mississippi' },
        { ID: 'MO', Name: 'Missouri' },
        { ID: 'MT', Name: 'Montana' },
        { ID: 'NE', Name: 'Nebraska' },
        { ID: 'NV', Name: 'Nevada' },
        { ID: 'NH', Name: 'New Hampshire' },
        { ID: 'NJ', Name: 'New Jersey' },
        { ID: 'NM', Name: 'New Mexico' },
        { ID: 'NY', Name: 'New York' },
        { ID: 'NC', Name: 'North Carolina' },
        { ID: 'ND', Name: 'North Dakota' },
        { ID: 'MP', Name: 'Northern Mariana Islands' },
        { ID: 'OH', Name: 'Ohio' },
        { ID: 'OK', Name: 'Oklahoma' },
        { ID: 'OR', Name: 'Oregon' },
        { ID: 'PW', Name: 'Palau' },
        { ID: 'PA', Name: 'Pennsylvania' },
        { ID: 'PR', Name: 'Puerto Rico' },
        { ID: 'RI', Name: 'Rhode Island' },
        { ID: 'SC', Name: 'South Carolina' },
        { ID: 'SD', Name: 'South Dakota' },
        { ID: 'TN', Name: 'Tennessee' },
        { ID: 'TX', Name: 'Texas' },
        { ID: 'UT', Name: 'Utah' },
        { ID: 'VT', Name: 'Vermont' },
        { ID: 'VI', Name: 'Virgin Islands' },
        { ID: 'VA', Name: 'Virginia' },
        { ID: 'WA', Name: 'Washington' },
        { ID: 'WV', Name: 'West Virginia' },
        { ID: 'WI', Name: 'Wisconsin' },
        { ID: 'WY', Name: 'Wyoming' },
    ],
    displayExpr: 'Name',
    valueExpr: 'ID',
    icon: false,
    id: 'state',
    elementAttr: {
        'field-id': 'state-code-id',
    },
}
const legalNameEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Legal Entity Name',
    // labelMode: 'static',
    mode: 'text',
    icon: true,
    tooltipText: 'Provide the full legal name as listed on your formation document and bank account.',
    id: 'legal',
    elementAttr: {
        'field-id': 'legal-name-code-id',
    },
}

const nameEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'First Name',
    mode: 'text',
    icon: false,
    id: 'ppcc_name',
    elementAttr: {
        'field-id': 'name-code-id',
    },
}
const lastNameEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Last Name',
    mode: 'text',
    icon: false,
    id: 'ppcc_last_name',
    elementAttr: {
        'field-id': 'last-name-code-id',
    },
}
const emailEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Email',
    mode: 'email',
    icon: false,
    id: 'ppcc_email',
    elementAttr: {
        'field-id': 'email-code-id',
    },
}
const titleEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Title',
    mode: 'text',
    icon: false,
    id: 'ppcc_title',
    elementAttr: {
        'field-id': 'title-code-id',
    },
}
const phoneEditorOptions = {
    stylingMode: 'outlined',
    labelTemplateText: 'Phone Number',
    mode: 'tel',
    mask: '+1 (X00) 000-0000',
    maskRules: {
        X: /[02-9]/,
    },
    maskInvalidMessage: 'The phone must have a correct USA phone format',
    icon: false,
    id: 'ppcc_phone',
    inputAttr: {
        autoComplete: 'new-phone',
    },
    elementAttr: {
        'field-id': 'phone-code-id',
    },
}
const submitButtonAttributes = { class: 'submit-button', 'field-id': 'submit-code-id' }

export default BusinessDetailsForm
