import { useCallback, useEffect, useState } from 'react'
import { useAuth, useConfig } from '../../../contexts'
import notify from 'devextreme/ui/notify'
import QRCode from 'react-qr-code'
import Form, { Item, ButtonItem, ButtonOptions } from 'devextreme-react/form'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import './RegisterForm.scss'
import '../form.scss'
import { ChallengeEnum } from '../../../clients/api/auth'
import { HttpErrorCodes } from '../../../clients/api/auth/Model'
import { ErrorMessageProps } from '../../common/errorMessage/Model'

const errorMessageClear = new ErrorMessageProps()

const TwoFactorAuthRegisterForm = (): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const navigate = useNavigate()

    const { setup2FA, username, loading } = useAuth()
    const { firm, commonConfig } = useConfig()

    const customContent = commonConfig.Content.TFARegister

    const [secret2FA, setSecret2FA] = useState('')

    const getSecret2FA = useCallback(async () => {
        try {
            setErrorMessage(errorMessageClear)
            const setup2FAResponse = await setup2FA()
            if (setup2FAResponse == null || setup2FAResponse.code !== HttpErrorCodes.OK) {
                setErrorMessage(new ErrorMessageProps(`2FA Register Error: ${setup2FAResponse?.message}`, ''))
                return
            }
            setSecret2FA(setup2FAResponse.secret)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            setErrorMessage(new ErrorMessageProps('2FA Register Error: Server Error - Missing data to register the Two Factor Authentication (TOTP App)', ''))
            const msg = error.response?.data.error == null ? 'Two Factor Authentication Error' : error.response.data.error.message
            notify(msg, 'error', 3000)
        }
    }, [setup2FA])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        if (secret2FA === '') getSecret2FA()
    }, [getSecret2FA, secret2FA])

    const onVerifyCodeClick = useCallback(() => {
        navigate('/two-factor-auth-verify', { state: { isRegistered: ChallengeEnum.MFA_SETUP_LM } })
    }, [navigate])

    const copyToClipboard = async (e: unknown): Promise<void> => {
        try {
            await navigator.clipboard.writeText(secret2FA)
        } catch (error) {
            console.log('Failed to copy to clipboard')
        }
    }

    return (
        <SingleCard {...customContent}>
            <>
                {loading && <Loading />}
                {!loading && errorMessage.message !== '' && <ErrorMessage {...errorMessage} />}
                {!loading && errorMessage.message === '' && secret2FA === '' && <ErrorMessage message={'2FA Register Error: No 2FA Code'} detail="" />}
                {!loading && errorMessage.message === '' && secret2FA !== '' && (
                    <Form elementAttr={{ 'field-id': 'register-code-id' }}>
                        <Item>
                            <div className="TwoFA-QR">
                                <QRCode value={`otpauth://totp/${firm}:${username}?secret=${secret2FA}`} />
                            </div>
                        </Item>
                        <Item>
                            <p>
                                2FA Key: <b>{secret2FA}</b>
                            </p>
                        </Item>
                        <ButtonItem cssClass={'secondary-button'}>
                            <ButtonOptions text={'Copy (2FA Key) to Clipboard'} width={'100%'} onClick={copyToClipboard} />
                        </ButtonItem>
                        <ButtonItem cssClass={'primary-button'}>
                            <ButtonOptions text={'Verify Code'} type={'default'} width={'100%'} onClick={onVerifyCodeClick} />
                        </ButtonItem>
                    </Form>
                )}
            </>
        </SingleCard>
    )
}

export default TwoFactorAuthRegisterForm
